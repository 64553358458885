import barba from "@barba/core";
import gsap from "gsap";

("use strict");

import { Cursor } from "../cursor/cursor";

import { Accordion } from "../accordion/accordion";
import { Banner } from "../banner/banner";
import { Contact } from "../contact/contact";
import { Footer } from "../footer/footer";
import { GridHome } from "../grid-home/grid-home";
import { GridItem } from "../grid-item/grid-item";
import { GridProjects } from "../grid-projects/grid-projects";
import { Header } from "../header/header";
import { Menu } from "../menu/menu";
import { NextPrev } from "../next-prev/next-prev";
import { ProjectInfo } from "../project-info/project-info";
import { SvgLogo } from "../svg-logo/svg-logo";
import { Theme } from "../theme/theme";
import { Waiting } from "../waiting/waiting";

export class Loader {
  constructor() {
    const loader = document.querySelector(".loader");

    // reset position of the loading screen
    gsap.set(loader, {
      scaleY: 0,
      rotation: 0,
      xPercent: 0,
      yPercent: -50,
      transformOrigin: "left center",
      autoAlpha: 1
    });

    function loaderIn() {
      // GSAP tween to stretch the loading screen across the whole screen
      return gsap.fromTo(
        loader,
        {
          rotation: 0,
          scaleY: 0,
          xPercent: 0
        },
        {
          duration: 0.6,
          xPercent: 0,
          scaleY: 1,
          rotation: 0,
          ease: "SlowMo.inOut",
          transformOrigin: "left center"
        }
      );
    }

    function loaderAway() {
      // GSAP tween to hide the loading screen
      return gsap.to(loader, {
        duration: 0.6,
        scaleY: 0,
        xPercent: 0,
        rotation: 0,
        transformOrigin: "right center",
        ease: "SlowMo.inOut"
      });
    }

    // do something before the transition starts
    barba.hooks.before(() => {
      document.querySelector("html").classList.add("is-transitioning");
      barba.wrapper.classList.add("is-animating");
    });

    // do something after the transition finishes
    barba.hooks.after(() => {
      document.querySelector("html").classList.remove("is-transitioning");
      barba.wrapper.classList.remove("is-animating");

      // re-render js after changed
      // ==> MODULES HERE <==
      new Accordion();
      new Banner();
      new Contact();
      new Footer();
      new GridHome();
      new GridItem();
      new GridProjects();
      new Header();
      new Menu();
      new NextPrev();
      new ProjectInfo();
      new SvgLogo();
      new Theme();
      new Waiting();

      // ==> GENERAL HERE <==
      new Cursor();
    });

    // scroll to the top of the page
    barba.hooks.enter(() => {
      window.scrollTo(0, 0);
    });

    barba.init({
      transitions: [
        {
          async leave() {
            await loaderIn();
          },
          enter() {
            loaderAway();
          }
        }
      ]
    });
  }
}
