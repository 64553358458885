"use strict";

export class Theme {
  constructor() {
    const html = document.getElementsByTagName("html")[0];
    const btnDark = document.getElementsByClassName("j-dark")[0];
    const btnLight = document.getElementsByClassName("j-light")[0];

    const lightTheme = "light";
    const darkTheme = "dark";

    const theme = localStorage.getItem("theme");
    if (theme) {
      html.dataset.theme = theme;
    } else {
      html.dataset.theme = lightTheme;
      localStorage.setItem("theme", lightTheme);
    }

    if (btnDark) {
      btnDark.addEventListener("click", () => {
        html.dataset.theme = darkTheme;
        localStorage.setItem("theme", darkTheme);
      });
    }

    if (btnLight) {
      btnLight.addEventListener("click", () => {
        html.dataset.theme = lightTheme;
        localStorage.setItem("theme", lightTheme);
      });
    }
  }
}
