import Splide from "@splidejs/splide";

("use strict");

export class GridHome {
  constructor() {
    const scrollElements = document.getElementsByClassName("j-scroll");

    const elementInView = (el, percentageScroll = 100) => {
      const elementTop = el.getBoundingClientRect().top;
      return (
        elementTop <=
        (window.innerHeight || document.documentElement.clientHeight) *
          (percentageScroll / 100)
      );
    };

    const handleScrollAnimation = () => {
      scrollElements.forEach(el => {
        if (elementInView(el, 75)) {
          el.classList.add("scrolled");
        }
      });
    };

    window.addEventListener("scroll", () => {
      handleScrollAnimation();
    });

    const allSplide = document.getElementsByClassName("js-splide");
    allSplide.forEach(element => {
      const splide = new Splide(element, {
        direction: "ttb",
        height: "auto",
        gap: 10,
        type: "loop",
        wheel: false,
        arrows: false,
        autoplay: true,
        classes: {
          pagination: "splide__pagination j-hoverable"
        }
      });

      splide.mount();
    });
  }
}
