'use strict';
import $ from 'jquery';

export class Accordion {
  constructor() {
    const url = window.location.href;
    const id = url.substring(url.lastIndexOf('#') + 1);


    if( id=="about" ){
      let open = document.getElementById("about");
      open.style.display="block";
      let btn = document.getElementsByClassName("accordion__clickable")[0];
      btn.classList.add("accordion__clickable--active");

    } 
    if( id=="contact" ){
      let open = document.getElementById("contact");
      open.style.display="block";
      let btn = document.getElementsByClassName("accordion__clickable")[1];
      btn.classList.add("accordion__clickable--active");

    }

    $(".accordion__clickable").on("click", function () {
      $(this).toggleClass("accordion__clickable--active").next().slideToggle(500);
    });
    
  }
}
