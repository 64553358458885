"use strict";

export class NextPrev {
  constructor() {
    //
    const project = document.getElementsByClassName("j-projectID")[0];

    if (project) {
      const projectId = project.id;
      const projectStringId = projectId.replace("project-", "");
      const thisProject = parseInt(projectStringId);
      const listproject = document.getElementsByClassName("j-dataProject");
      const numberProject = listproject.length - 1;
      let prev;
      let next;
      calculateNextPrev();

      const prevEl = document.querySelector(`[data-id="project-${prev}"]`);
      const nextEl = document.querySelector(`[data-id="project-${next}"]`);

      const prevLink = prevEl.dataset.link;
      const nextLink = nextEl.dataset.link;
      const prevImg = prevEl.dataset.image;
      const nextImg = nextEl.dataset.image;

      document
        .getElementsByClassName("j-prev-link")[0]
        .setAttribute("href", prevLink);
      document
        .getElementsByClassName("j-next-link")[0]
        .setAttribute("href", nextLink);
        document
        .getElementsByClassName("j-prev-link-img")[0]
        .setAttribute("href", prevLink);
      document
        .getElementsByClassName("j-next-link-img")[0]
        .setAttribute("href", nextLink);
      document
        .getElementsByClassName("j-prev-img")[0]
        .setAttribute("src", prevImg);
      document
        .getElementsByClassName("j-next-img")[0]
        .setAttribute("src", nextImg);

      function calculateNextPrev() {
        if (thisProject == 0) {
          prev = numberProject;
          next = thisProject + 1;
        } else if (thisProject == numberProject) {
          prev = thisProject - 1;
          next = 0;
        } else {
          prev = thisProject - 1;
          next = thisProject + 1;
        }
      }
    }
  }
}

