("use strict");

export class Waiting {
  constructor() {
    const waiting = document.getElementsByClassName("js-waiting")[0];
    setTimeout(() => {
      waiting.classList.add("fadeIn");
    }, 200);
    setTimeout(() => {
      waiting.classList.remove("fadeIn");
    }, 2000);
    setTimeout(() => {
      waiting.classList.add("removed");
    }, 2600); // because transition delay fade is 0.6s
  }
}
 