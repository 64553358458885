"use strict";

export class Cursor {
  constructor() {
    const cursorIn = document.querySelector(".j-cursor-in"); 
    const cursorOut = document.querySelector(".j-cursor-out");
    const square = document.querySelector(".j-cursor-square");

    // Reset style when loading each page
    onMouseLeave();

    document.addEventListener("mousemove", e => {
      cursorIn.style.transform = `translate(${e.clientX}px, ${
        e.clientY
      }px)`;
      cursorOut.style.transform = `translate(${e.clientX}px, ${
        e.clientY
      }px)`;
    });

    const hoverables = document.querySelectorAll(".j-hoverable");
    const iframes = document.querySelectorAll("iframe");

    // Mouse on j-hoverables
    for (let i = 0; i < hoverables.length; i++) {
      hoverables[i].addEventListener("mouseenter", onMouseHover);
      hoverables[i].addEventListener("mouseleave", onMouseLeave);
    }

    function onMouseHover() { 
      square.classList.add("animation");
    }

    function onMouseLeave() {
      square.classList.remove("animation");
    }
  }
}
