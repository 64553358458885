// Main javascript entry point
// Should handle bootstrapping/starting application

"use strict";

import "core-js";
import "regenerator-runtime/runtime";
import $ from "jquery";

import { Cursor } from "../_modules/cursor/cursor";
import { Loader } from "../_modules/loader/loader";

import { Accordion } from "../_modules/accordion/accordion";
import { Banner } from "../_modules/banner/banner";
import { Contact } from "../_modules/contact/contact";
import { Footer } from "../_modules/footer/footer";
import { GridHome } from "../_modules/grid-home/grid-home";
import { GridItem } from "../_modules/grid-item/grid-item";
import { GridProjects } from "../_modules/grid-projects/grid-projects";
import { Header } from "../_modules/header/header";
import { Menu } from "../_modules/menu/menu";
import { NextPrev } from "../_modules/next-prev/next-prev";
import { ProjectInfo } from "../_modules/project-info/project-info";
import { SvgLogo } from "../_modules/svg-logo/svg-logo";
import { Theme } from "../_modules/theme/theme";
import { Waiting } from "../_modules/waiting/waiting";

$(() => {
  // https://patorjk.com/software/taag/#p=display&f=Calvin%20S&t=cocotabert
  console.log(`
    Developed by:
    ┌┐ ┌─┐┬─┐┌┬┐
    ├┴┐├┤ ├┬┘ │  🦄
    └─┘└─┘┴└─ ┴  
    https://www.linkedin.com/in/alessia-bertelli/
    `); 

  // Modules
  new Accordion();
  new Banner();
  new Contact();
  new Footer();
  new GridHome();
  new GridItem();
  new GridProjects();
  new Header();
  new Menu();
  new NextPrev();
  new ProjectInfo();
  new SvgLogo();
  new Theme();
  new Waiting();

  // General Modules
  new Cursor();
  new Loader();
});
