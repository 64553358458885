("use strict");

export class Banner {
  constructor() {
    const width = window.innerWidth;

    if (width > 1025) {
      document.addEventListener("mousemove", e => {
        //Variables regarding the DOM
        const followItem = document.getElementsByClassName("j-follow");

        followItem.forEach(el => {
          let resistance = 1;

          //Getting the coordinates
          const mouseX = e.clientX;
          const mouseY = e.clientY;

          const windowX = window.innerWidth;
          const windowY = window.innerHeight;

          //Inverting the mouse coordinates
          const invertMouseX = windowX - mouseX;
          const invertMouseY = windowY - mouseY;

          //Determining which percentage of the screen the mouse is in
          const xPercent = (mouseX / windowX) * 100;
          const yPercent = (mouseY / windowY) * 100;

          //Just storing the value that is half the screen height and width
          const halfScreenx = windowX / 2;
          const halfScreeny = windowY / 2;

          //Declaring the side and orientation values to be used later
          //They will get used to determin which corner of the screen the mouse is in
          let side, orientation;

          //Mouse in top left corner?
          if (xPercent <= 50 && yPercent <= 50) {
            side = "Left";
            orientation = "Top";
            resistance = el.dataset.resistance;
            el.style.transform =
              "translate(" +
              ((invertMouseX - halfScreenx) / resistance) * -1 +
              "px," +
              ((invertMouseY - halfScreeny) / resistance) * -1 +
              "px)";
          }

          //Mouse in bottom left corner?
          if (xPercent <= 50 && yPercent > 50) {
            side = "Left";
            orientation = "Bottom";
            resistance = el.dataset.resistance;
            el.style.transform =
              "translate(" +
              ((invertMouseX - halfScreenx) / resistance) * -1 +
              "px," +
              (mouseY - halfScreeny) / resistance +
              "px)";
          }

          //Mouse in top right corner?
          if (xPercent > 50 && yPercent <= 50) {
            side = "Right";
            orientation = "Top";
            resistance = el.dataset.resistance;
            el.style.transform =
              "translate(" +
              (mouseX - halfScreenx) / resistance +
              "px," +
              ((invertMouseY - halfScreeny) / resistance) * -1 +
              "px)";
          }

          //Mouse in bottom right corner?
          if (xPercent > 50 && yPercent > 50) {
            side = "Right";
            orientation = "Bottom";
            resistance = el.dataset.resistance;
            el.style.transform =
              "translate(" +
              (mouseX - halfScreenx) / resistance +
              "px," +
              (mouseY - halfScreeny) / resistance +
              "px)";
          }
        });
      });
    }
  }
}
